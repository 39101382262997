import { Component, OnInit } from '@angular/core';
import {BoothsService} from '../../services/booths.service';
import {ModalController} from '@ionic/angular';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

interface Booth {
  id: number;
  name: string;
  aile: string;
  desc: string;
  phone: string;
  x: number;
  y: number;
}



class closeData {
  data: string;
  booth: Booth;
}

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss'],
})
export class SearchboxComponent implements OnInit {
  searchBar: any;
  public searchText: string;
  boothData: Booth[];
  rawboothData: any = [];
  searchTerm: string;
  oncloseData:closeData;
  show_list=false;

  constructor(public boothsService: BoothsService,public modalController: ModalController) { }

  ngOnInit() {

    this.searchText = '';

    this.rawboothData = JSON.parse(localStorage.getItem('booths'));
    //  this.rawboothData=booths_local;
    console.log('loading data waiting');
    console.log('data loaded');
    console.log(this.rawboothData);
    /*this.boothData = this.rawboothData.filter(currentFood => {
      if (currentFood.name &&  this.searchTerm) {
        return (currentFood.name.toLowerCase().indexOf( this.searchTerm.toLowerCase()) > -1);
      }
    });*/
    console.log(this.boothData);

  }

  closeModalButton(id_: number,name: string,x: number,y: number,booth: Booth) {

console.log('Close from:'+id_.toString());
    this.oncloseData=new closeData();

    this.oncloseData.data='accept';
    this.oncloseData.booth=booth;
    this.modalController.dismiss(this.oncloseData);

  }
  closeModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.oncloseData=new closeData();
    this.oncloseData.data='close';

    console.log(this.oncloseData);
    this.modalController.dismiss(this.oncloseData);
  }

  clear() {


  }
  search(query?: string) {

    console.log(query);
    this.searchText = query;
    this.show_list=true;
    if(query==''){this.show_list=false;}
    /*this.boothData = this.rawboothData.filter(currentFood => {
      if (currentFood.name &&  this.searchTerm) {
        return (currentFood.name.toLowerCase().indexOf( this.searchTerm.toLowerCase()) > -1);
      }
    });*/

    //this.dBase = allPatients.filter(item => item.name.includes(this.searchQuery));
  }
}
