import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchboxComponent } from './searchbox.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {FormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";


@NgModule({
  imports: [
    CommonModule,
    Ng2SearchPipeModule,
    FormsModule,
    IonicModule,
  ],
  exports: [
    SearchboxComponent
  ],
  declarations: [SearchboxComponent]
})
export class SearchboxModule { }
