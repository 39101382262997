import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BoothsService} from './services/booths.service';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import 'hammerjs';
import { SearchboxModule } from './mapa/searchbox/searchbox.module';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
export class MyHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
    // override hammerjs default configuration
    'rotate':{enabled:true},
    'pan': {threshold: 5},
    'swipe': {
      velocity: 0.4,
      threshold: 20,
      direction: 31
    },
    'press':{}
  }
}


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule,Ng2SearchPipeModule,SearchboxModule],
  providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, BoothsService,{ provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig }],
  bootstrap: [AppComponent],
  exports:[Ng2SearchPipeModule]

})
export class AppModule {}
